<template>
    <div class="wrapper">
        <div class="container-fluid">
            <!-- Page-Title -->
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <h2 class="page-title">Peta Bayi</h2>
                    </div>
                </div>
            </div>
            <!-- End container-fluid -->
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from '@/views/layouts/FooterComponent.vue';
export default {
    name: 'PetaBayi',
    components:{
        Footer
    },
    data() {
        return {};
    },
};
</script>

<style lang="css" scoped>
</style>
